<template>
    <comp-form
        title="商城自提点库存"
        ref="comp_form"
        submit-api="/gateway/apps/o2o/api/terminal/goods/addTerminalGoods"
        edit-api="/gateway/apps/o2o/api/terminal/goods/updateTerminalGoods"
        detail-api="/gateway/apps/o2o/api/terminal/goods/getById"
        id-key="id"
        :submit-before="onSubmitBefore"
        :data-before="onDataBefore"
        :data-watch="dataWatch"
        @on-submit="onReset"
    >
        <template v-slot:form-box="data">
            <ElemFormItem title="群组" name="terminalGroupId" width="60px">
                <Select v-model="data.form.terminalGroupId" filterable style="width:300px">
                    <Option v-for="item in groups" :value="item.id" :key="item.id">{{ item.groupName }}</Option>
                </Select>
            </ElemFormItem>
            <ElemFormItem title="供应商" name="supplierId" width="60px">
                <Select v-model="data.form.supplierId" filterable style="width:300px">
                    <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                </Select>
            </ElemFormItem>
            <ElemFormItem title="商品" name="goodsId" width="60px">
                <ElemGoods :supplierId="data.form.supplierId" :value="data.form.goodsObj" @on-change="onChangeGoods($event, data.form)"></ElemGoods>
            </ElemFormItem>
            <ElemFormItem title="规格" name="goodsSpId" width="60px">
                <Select v-model="data.form.goodsSpId" filterable style="width:300px">
                    <Option v-for="item in goodsSp" :value="item.id" :key="item.id">{{ item.spName }}</Option>
                </Select>
            </ElemFormItem>
            <ElemFormItem title="库存" name="distCount" width="60px">
                <Input style="width:300px" maxlength="5" type="number" v-model="data.form.distCount" placeholder="请填写库存"></Input>
            </ElemFormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../../jointly/components/CompForm.vue"
import ElemFormItem from "../../../jointly/components/elem-form-item.vue"
import ElemGoods from "../../components/ElemGoods.vue"

export default {
    components: { CompForm, ElemFormItem, ElemGoods },

    data() {
        return {
            groups: null,
            suppliers: null,
            goods: null,
            goodsSp: null,

            dataWatch: {
                terminalGroupId: v => {
                    this.getSupplier(v)
                    this.getGoods(v)
                },
                goodsId: v => {
                    this.getGoodsSp(v)
                },
            },
        }
    },

    mounted() {
        this.getGroup()
    },

    methods: {
        getGroup() {
            this.$get("/gateway/apps/o2o/api/terminal/goods/getTerminalGroupOptions", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.groups = res.dataList
                }
            })
        },

        getSupplier(id) {
            if (!id) return (this.suppliers = null)

            this.$get("/gateway/apps/o2o/api/terminal/goods/listGroupSupplierByGroupId", {
                terminalGroupId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.suppliers = res.dataList
                }
            })
        },

        getGoods(id) {
            if (!id) return (this.goods = null)

            this.$get("/gateway/apps/o2o/api/terminal/goods/listGoodsByGroupId", {
                terminalGroupId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.goods = res.dataList
                }
            })
        },

        getGoodsSp(id) {
            if (!id) return (this.goodsSp = null)

            this.$get("/gateway/apps/o2o/api/terminal/goods/listGoodsSpByGoodsId", {
                goodsId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.goodsSp = res.dataList
                }
            })
        },

        /**
         * 打开表单窗口
         */
        open(id) {
            this.$refs.comp_form.open(id)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.oemCode = parent.vue.oemInfo.oemCode
            data.updatedBy = parent.vue.loginInfo.userinfo.custGlobalId
            return data
        },

        /**
         * 数据前置处理
         */
        onDataBefore(data) {
            if (data.goodsId && data.goodsName) {
                data.goodsObj = {
                    key: data.goodsId,
                    value: data.goodsName,
                }
            }
            return data
        },

        onChangeGoods(evt, form) {
            form.goodsObj = evt.value
            form.goodsId = evt.value?.key
        },
    },
}
</script>
